import logo from "./logo.svg";
import "./App.css";
import "./nav.css";
import Background from "./Pages/Background";
import Navbar from "./Components/Navbar";
import { AppProvider } from "./AppContext";
import Router from "./Router";

function App() {
  return (
    <div className="App">
      <AppProvider>
        <Background>
          <Navbar />

          <Router />
        </Background>
      </AppProvider>
    </div>
  );
}

export default App;
