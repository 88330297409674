import React, { useContext } from "react";
import "./eligenos.css";
import "animate.css";
import AppContext from "../../AppContext";
import CheckIcon from "@mui/icons-material/Check";

function Eligenos() {
  const { menu, setMenu } = useContext(AppContext);
  const animation =
    menu === "eligenos"
      ? "animate__animated animate__slideInLeft"
      : "animate__animated animate__slideOutLeft";

  return (
    <section class={animation}>
      <div
        className="nosotros flex "
        style={{ backgroundColor: "transparent" }}
      >
        <div
          className="bg-white flex flex-col gap-4 justify-center items-center"
          style={{ width: "45vw" }}
        >
          <h1>ELÍGENOS</h1>

          <div>
            <div className="flex flex-col gap-4" style={{ width: "30vw" }}>
              <h3>¿Por qué somos tu mejor opción?</h3>
              <div className="flex gap-1 ">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Brindamos asesorías por expertos en la fabricación de
                  concreto.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Trabajamos con insumos de la mayor calidad.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Llegamos al lugar donde tú estés para contribuir en la
                  ejecución de tus proyectos y realizar el cubicaje necesario.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Aumentamos la eficiencia al reducir tiempos a comparación de
                  otros métodos de suministro de concreto.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Garantizamos la mayor calidad al contar con procesos de
                  conformidad de la resistencia a la compresión.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "15vw",
            clipPath: "polygon(0 0, 0% 100%, 40% 0)",
            backgroundColor: "white ",
          }}
        ></div>
      </div>
    </section>
  );
}

export default Eligenos;
