import { createContext, useState, useRef } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [menu, setMenu] = useState("inicio");

  return (
    <AppContext.Provider
      value={{
        menu,
        setMenu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };
export default AppContext;
