import React, { useContext, useState } from "react";
import "./contactanos.css";
import AppContext from "../../AppContext";
import CheckIcon from "@mui/icons-material/Check";
import { Alert, Button, IconButton, Snackbar, TextField } from "@mui/material";
import { Textarea } from "@mui/joy";
import emailjs from "@emailjs/browser";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Contactanos() {
  const { menu } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const animation =
    menu === "contactanos"
      ? "w-full animate__animated animate__slideInRight"
      : "w-full animate__animated animate__slideOutRight";

  const serviceId = "service_4auln9t";
  const templateId = "template_xye334o";
  const publicKey = "zrLzqVg65PKR4PnWM";

  const messageData = {
    user_name: name,
    user_email: email,
    message: message,
  };

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const OpenSuccessSnackBar = () => {
    setOpen(true);
  };
  const OpenErrorSnackBar = () => {
    setOpen2(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const sendEmail = () => {
    emailjs.send(serviceId, templateId, messageData, publicKey).then(
      (result) => {
        OpenSuccessSnackBar();
      },
      (error) => {
        OpenErrorSnackBar();
      }
    );
  };

  return (
    <section className={animation}>
      <div
        className="nosotros flex justify-end "
        style={{ backgroundColor: "transparent" }}
      >
        <div
          style={{
            width: "15vw",
            clipPath: "polygon(100% 0, 100% 100%, 60% 0)",
            backgroundColor: "white ",
          }}
        ></div>
        <div
          className="bg-white flex flex-col gap-4 justify-center items-center"
          style={{ width: "45vw" }}
        >
          <h1>CONTÁCTANOS</h1>

          <div>
            <div className="flex flex-col gap-4" style={{ width: "30vw" }}>
              {/* <h3>¿Por qué somos tu mejor opción?</h3> */}

              <div className="flex gap-1 items-center">
                <div className="p-2">
                  <LocalPhoneIcon />
                </div>
                <h4 className="text-left text-base">Teléfono: 950010037</h4>
              </div>
              <div className="flex gap-1 items-center">
                <IconButton
                  onClick={() => {
                    const nuevaPestana = window.open(
                      "https://api.whatsapp.com/send?phone=51950010037",
                      "_blank"
                    );
                    nuevaPestana.focus();
                  }}
                >
                  <WhatsAppIcon sx={{ color: "rgb(31,211,102)" }} />
                </IconButton>
                <h4 className="text-left text-base">WhatsApp: 950010037</h4>
              </div>
              <div className="flex gap-1 items-center">
                <div className="p-2">
                  <EmailIcon />
                </div>
                <h4 className="text-left text-base">
                  Correo: ventas@movicreto.pe
                </h4>
              </div>
              <div className="flex gap-1 items-center">
                <div className="p-2">
                  <LocationOnIcon />
                </div>
                <h4 className="text-left text-base">
                  Ubicación: Chimbote – Ancash
                </h4>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <TextField
                  label="Nombre"
                  variant="outlined"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <TextField
                  label="Correo"
                  variant="outlined"
                  type={"email"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <Textarea
                minRows={2}
                placeholder="Escribe tu mensaje…"
                variant="outlined"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  disabled={name === "" || email === "" || message === ""}
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  Enviar Email
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Se envió el correo
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Ocurrió un problema
        </Alert>
      </Snackbar>
    </section>
  );
}

export default Contactanos;
