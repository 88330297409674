import React, { useContext } from "react";
import "./nosotros.css";
import "animate.css";
import AppContext from "../../AppContext";
import CheckIcon from "@mui/icons-material/Check";

function Nosotros() {
  const { menu, setMenu } = useContext(AppContext);
  const animation =
    menu === "nosotros"
      ? "animate__animated animate__slideInLeft"
      : "animate__animated animate__slideOutLeft";

  const textoMision =
    "Somos una empresa dedicada al suministro de concreto premezclado para el rubro de construcción, siendo aliados estratégicos en la realización de proyectos.";

  const textoVision =
    "Ser la empresa líder en la elaboración y distribución de concreto premezclado en el Perú, gracias a la constante innovación en nuestros procesos y equipos.";

  return (
    <section class={animation}>
      <div
        className="nosotros flex "
        style={{ backgroundColor: "transparent" }}
      >
        <div
          className="bg-white flex flex-col gap-4 justify-center items-center"
          style={{ width: "45vw" }}
        >
          <h1>NOSOTROS</h1>
          <div>
            <div className="flex flex-col" style={{ width: "30vw" }}>
              <h3>Misión</h3>
              <h4>{textoMision}</h4>
            </div>
          </div>
          <div className="flex flex-col" style={{ width: "30vw" }}>
            <h3>Visión</h3>
            <h4>{textoVision}</h4>
          </div>
          <div>
            <div className="flex flex-col gap-4" style={{ width: "30vw" }}>
              <h3>Valores</h3>
              <div className="flex gap-1 ">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Calidad: Nos comprometemos a entregar concreto premezclado de
                  la más alta calidad, cumpliendo con estándares rigurosos en
                  cada etapa del proceso, desde la selección de materiales hasta
                  la entrega al cliente.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Confianza y Transparencia: Construimos relaciones basadas en
                  la confianza y la transparencia con nuestros clientes,
                  colaboradores y proveedores. Cumplimos nuestras promesas y
                  trabajamos de manera abierta y honesta en todo momento.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Excelencia en el Servicio: Nos esforzamos por superar las
                  expectativas de nuestros clientes en cada interacción. Nuestro
                  equipo altamente capacitado está dedicado a brindar un
                  servicio excepcional y una atención personalizada.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "15vw",
            clipPath: "polygon(0 0, 0% 100%, 40% 0)",
            backgroundColor: "white ",
          }}
        ></div>
      </div>
    </section>
  );
}

export default Nosotros;
