import React, { useContext } from "react";
import AppContext from "../AppContext";
import "./navbar.css";

function Navbar() {
  const { menu, setMenu } = useContext(AppContext);

  return (
    <nav>
      <div
        class="nav-container"
        style={{
          animation: menu === "inicio" ? "navtop 1s" : "navbot 1s",
          bottom: menu === "inicio" ? "5vh" : "0vh",
        }}
      >
        <ul id="top-nav-options">
          <li class={menu === "inicio" ? "nav_active " : "nav"}>
            <a
              id="inicio"
              onClick={() => {
                setMenu("inicio");
              }}
            >
              Inicio
            </a>
          </li>
          <li class={menu === "nosotros" ? "nav_active" : "nav"}>
            <a
              id="nosotros"
              onClick={() => {
                setMenu("nosotros");
              }}
            >
              Nosotros
            </a>
          </li>
          <li class={menu === "servicios" ? "nav_active" : "nav"}>
            <a
              id="servicios"
              onClick={() => {
                setMenu("servicios");
              }}
            >
              Servicios
            </a>
          </li>
          <li class={menu === "eligenos" ? "nav_active" : "nav"}>
            <a
              id="eligenos"
              onClick={() => {
                setMenu("eligenos");
              }}
            >
              Elígenos
            </a>
          </li>
          <li class={menu === "contactanos" ? "nav_active" : "nav"}>
            <a
              id="contactanos"
              onClick={() => {
                setMenu("contactanos");
              }}
            >
              Contáctanos
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
