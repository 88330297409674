import React, { useContext } from "react";
import "./inicio.css";
import logoName from "../../assets/logoName.png";
import logo from "../../assets/logo.png";
import "animate.css";
import AppContext from "../../AppContext";

function Inicio() {
  const { menu, setMenu } = useContext(AppContext);
  const animation =
    menu === "inicio"
      ? "animate__animated animate__slideInLeft"
      : "animate__animated animate__slideOutLeft";

  return (
    <section class={animation}>
      <div class="white-inicio">
        <div class="orange-triangule">
          <div style={{ transform: "skewX(45deg)" }}>
            <img class="cover-logo" src={logo}></img>
            <img class="cover-logo-name" src={logoName}></img>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Inicio;
