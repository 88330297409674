import React from "react";
import backgroundVideo from "../assets/cover.mp4";
import "./background.css";

function Background({ children }) {
  return (
    <header class="cover">
      <video
        class="cover-video"
        src={backgroundVideo}
        loop=""
        autoplay="autoplay"
        muted="muted"
      ></video>
      {children}
    </header>
  );
}

export default Background;
