import React, { useContext } from "react";
import "./servicios.css";
import "animate.css";
import AppContext from "../../AppContext";
import CheckIcon from "@mui/icons-material/Check";

function Servicios() {
  const { menu, setMenu } = useContext(AppContext);
  const animation =
    menu === "servicios"
      ? "w-full animate__animated animate__slideInRight"
      : "w-full animate__animated animate__slideOutRight";

  return (
    <section class={animation}>
      <div
        className="nosotros flex justify-end "
        style={{ backgroundColor: "transparent" }}
      >
        <div
          style={{
            width: "15vw",
            clipPath: "polygon(100% 0, 100% 100%, 60% 0)",
            backgroundColor: "white ",
          }}
        ></div>
        <div
          className="bg-white flex flex-col gap-4 justify-center items-center"
          style={{ width: "45vw" }}
        >
          <h1>SERVICIOS</h1>

          <div>
            <div className="flex flex-col gap-4" style={{ width: "30vw" }}>
              {/* <h3>¿Por qué somos tu mejor opción?</h3> */}
              <div className="flex gap-1 ">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Servicio de vaciado de concreto premezclado en todas las
                  resistencias: Ofrecemos una amplia gama de mezclas de concreto
                  premezclado para adaptarnos a tus necesidades específicas,
                  desde proyectos residenciales hasta industriales.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Servicio de bombeo: Proporciona equipos y servicios de bombeo
                  de concreto para proyectos que requieren colocación en lugares
                  de difícil acceso o en alturas elevadas.
                </h4>
              </div>
              <div className="flex gap-1">
                <CheckIcon />
                <h4 className="text-left text-base">
                  Venta de Prefabricados: Además de concreto premezclado,
                  ponemos a tu disposición una amplia variedad de productos
                  prefabricados de concreto, listos para usar en tus proyectos
                  de construcción.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Servicios;
