import React, { useContext } from "react";
import AppContext from "./AppContext";
import Contactanos from "./Pages/Contactanos/Contactanos";
import Eligenos from "./Pages/Eligenos/Eligenos";
import Inicio from "./Pages/Inicio/Inicio";
import Nosotros from "./Pages/Nosotros/Nosotros";
import Servicios from "./Pages/Servicios/Servicios";

function Router() {
  const { menu, setMenu } = useContext(AppContext);
  return (
    <div style={{ zIndex: "10", display: "flex" }}>
      {/* <Inicio />
      <Nosotros />
      <Servicios />
      <Nosotros />
      <Nosotros /> */}
      {menu === "inicio" ? (
        <Inicio />
      ) : menu === "nosotros" ? (
        <Nosotros />
      ) : menu === "servicios" ? (
        <Servicios />
      ) : menu === "eligenos" ? (
        <Eligenos />
      ) : (
        menu === "contactanos" && <Contactanos />
      )}
    </div>
  );
}

export default Router;
